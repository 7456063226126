import { useEffect } from 'react';
import { useService } from 'envoc-request';
import { AccountService } from 'api';
import { authSelectors, useReduxSelector } from 'shared/state';

export default function TimeZoneReporter() {
  const { user } = useReduxSelector(authSelectors.getAuth);
  const { submitRequest } = useService(
    AccountService.updateTimezone,
    {
      body: {
        reportedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    {
      autoExecute: false,
    }
  );
  useEffect(() => {
    if (user) {
      submitRequest();
    }
  }, [submitRequest, user]);
  return <></>;
}
