import { createElement, ReactHTML, ReactNode, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import { useShadowScrollContext } from './ShadowScrollContext';

export interface ScrollAreaProps {
  children?: ReactNode;
  className?: string;
  tag?: keyof ReactHTML;
}

export default function ScrollArea({
  children,
  className,
  tag = 'div',
}: ScrollAreaProps) {
  const shadowRef = useShadowScrollContext();
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = targetRef.current;
    if (!target) {
      return;
    }
    const shadow = shadowRef.current;
    if (!shadow) {
      return;
    }

    return handleScrollShadows(target, shadow);
  }, [shadowRef]);

  return createElement(tag, { ref: targetRef, className: className }, children);
}

const wiggleRoomPx = 10; // how many pixels the user needs to scroll before the shadow 'activates'
const shadows = [
  '0px 20px  8px -16px', // top
  '-20px 0px  8px -16px', // right
  '0px -20px  8px -16px', // bottom
  '20px 0px  8px -16px', // left
];
function handleScrollShadows(target: HTMLDivElement, shadow: HTMLDivElement) {
  if (!global.ResizeObserver) {
    return;
  }
  // We want to ensure that the shadows update as target resizes
  // in addition, should any child in target resize then it may result in the scrollbar state changing
  // since there is no direct event for 'scrollWidth' available we just listen for resize events to the direct defendants
  // since we might have conditionally rendered children under target, we need the MutationObserver to handle those additions / subtractions

  const shadowThrottle = throttle(drawShadows, 10, {
    trailing: true,
    leading: false,
  });

  shadowThrottle();
  target.addEventListener('scroll', shadowThrottle, { passive: true });
  const resizeObserver = new ResizeObserver(shadowThrottle);
  resizeObserver.observe(target);

  for (let index = 0; index < target.childElementCount; index++) {
    const element = target.children[index];
    resizeObserver.observe(element);
  }

  const mutationObserver = new MutationObserver(handleMutation);
  mutationObserver.observe(target, { childList: true });

  return () => {
    target.removeEventListener('scroll', shadowThrottle);
    resizeObserver.disconnect();
    mutationObserver.disconnect();
    shadow.style.boxShadow = '';
    shadowThrottle.cancel();
  };

  function handleMutation(mutations: MutationRecord[]) {
    // each time a children of target are added or removed, we should ensure we listen to resizes of those elements
    for (let mi = 0; mi < mutations.length; mi++) {
      const mutation = mutations[mi];
      for (let ei = 0; ei < mutation.removedNodes.length; ei++) {
        const node = mutation.removedNodes[ei];
        if (node.nodeType === 1) {
          //see: https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
          resizeObserver.unobserve(node as Element);
        }
      }
      for (let ei = 0; ei < mutation.addedNodes.length; ei++) {
        const node = mutation.addedNodes[ei];
        if (node.nodeType === 1) {
          //see: https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
          resizeObserver.unobserve(node as Element);
        }
      }
    }
  }

  function drawShadows() {
    const distances = [
      target.scrollTop, // top
      target.scrollWidth - target.scrollLeft - target.clientWidth, //right
      target.scrollHeight - target.scrollTop - target.clientHeight, // bottom
      target.scrollLeft, // left
    ];

    const toRender = [];
    for (let index = 0; index < distances.length; index++) {
      const distance = distances[index];

      const hasShadow = distance > wiggleRoomPx;
      if (!hasShadow) {
        continue;
      }
      toRender.push(`inset ${shadows[index]} rgb(0 0 0 / 20%)`);
    }

    shadow.style.boxShadow = toRender.join(',');
  }
}
