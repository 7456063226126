import { FormattedDate, useIntl } from 'react-intl';
import Cell from './Cell';

// default (displayFormat prop not passed): 12/24/2022
// short: 12/24/22
// full: Saturday, December 24, 2022
// medium: Dec 24, 2022
// long: December 24, 2022
type DisplayFormats = 'short' | 'full' | 'medium' | 'long';

interface FormattedDateDisplayProps {
  date: string;
  displayFormat?: DisplayFormats;
}

export function FormattedDateDisplay({
  displayFormat,
  date,
}: FormattedDateDisplayProps) {
  const intl = useIntl();

  return (
    <Cell
      value={
        <FormattedDate
          dateStyle={displayFormat}
          value={dateOnly(date)}
          timeZone="UTC"
        />
      }
      title={intl.formatDate(date, { timeZone: 'UTC' })}
    />
  );

  function dateOnly(date: string) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const isValidDate = dateRegex.test(date);
    const dateValue = isValidDate ? Date.parse(date) : NaN;
    const isNotValidDate = isNaN(dateValue);

    if (isNotValidDate) {
      const errorMessage = `Invalid "date only" value of ${date} provided to FormattedDateDisplay component. Please provide "date only" string of format YYYY-MM-DD.`;
      if (process.env.NODE_ENV !== 'production') {
        throw new Error(errorMessage);
      } else {
        console.error(errorMessage);
      }
    } else {
      return dateValue;
    }
  }
}
