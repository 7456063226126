import { createSelector } from '@reduxjs/toolkit';
import { Role } from 'features/Auth';
import { AuthReduxState } from './types';

export const getAuth = (state: AuthReduxState) => state.auth;
export const getUser = (state: AuthReduxState) => getAuth(state).user;
export const getCookieExpiration = (state: AuthReduxState) =>
  getAuth(state).cookieExpirationMinutes;
export const getUserRoles = (state: AuthReduxState): Role[] =>
  getUser(state)?.roleNames?.map((value) => value as Role) ?? [];
export const getAuthTicketState = createSelector(
  getAuth,
  (state) => state.authTicketState
);
