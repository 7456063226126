import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './auth';
import { uiReducer } from './ui';

// TODO: for code splitting, how does this impact us?
const reducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
});

export { reducer as default };
