import React from 'react';
import classNames from 'classnames';

export interface SingleActionPageProps {
  children: React.ReactNode;
  className?: string;
}

export default function SingleActionPage({
  children,
  className,
}: SingleActionPageProps) {
  return (
    <div
      className={classNames(
        'flex content-center justify-center w-full min-h-screen overflow-y-scroll single-action-page bg-gray-50',
        className
      )}>
      <div className="w-full px-10 py-8 mx-1 my-auto bg-white rounded-lg shadow sm:mx-auto sm:w-max">
        {children}
      </div>
    </div>
  );
}
