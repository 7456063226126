import { CellProps } from 'react-table';

export default function AvatarCell(props: CellProps<[]>) {
  if (props.value.length === 0) {
    return '';
  }

  const list = props.value.map((item: string) => (
    <span
      key={props.value.indexOf(item)}
      className="inline-flex items-center justify-center w-6 h-6 bg-gray-600 rounded-full ring-1 ring-gray-100"
      title={item}>
      <span className="text-xs font-medium leading-none text-white">
        {item.substring(0, 2).toUpperCase()}
      </span>
    </span>
  ));

  return (
    <div className="flex m-2 -space-x-1 overflow-hidden cursor-default">
      {list}
    </div>
  );
}
