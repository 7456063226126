import classNames from 'classnames';

export interface LogoProps {
  sizeClass?: 'h-48';
}

export default function Logo({ sizeClass }: LogoProps) {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/img/homegrown-logo.jpg`}
      alt="Homegrown - Benefits for eFMNP"
      className={classNames('rounded-md', sizeClass)}
    />
  );
}
