import { ReactNode } from 'react';

export interface FooterProps {
  children: ReactNode;
}

export default function Footer({ children }: FooterProps) {
  return (
    <div className="flex flex-row flex-wrap justify-end flex-shrink gap-2 p-4">
      {children}
    </div>
  );
}
