import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AccountService } from 'api';

declare module 'axios' {
  export interface AxiosRequestConfig {
    hasLoadingMessage?: boolean;
  }
}

export const loadUser = createAsyncThunk('auth/loadUser', async () => {
  const { result } = await AccountService.getLoggedInUserDetails({
    hasLoadingMessage: true,
  });
  return result;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await AccountService.logout({
    hasLoadingMessage: true,
  });
  // need to re-get a new Xsrf - they are tied to user
  await AccountService.getLoggedInUserDetails({ hasLoadingMessage: true });
});

export const getCookieExpiration = createAsyncThunk(
  'auth/cookieExpiration',
  async () => {
    const { result } = await AccountService.getCookieExpiration({
      hasLoadingMessage: true,
    });
    return result ?? 0;
  }
);

export const setNoAuth = createAction('auth/setNoAuth');

export const allowRedirects = createAction('auth/allowRedirects');
