import type { ReactNode } from 'react';
import classNames from 'classnames';

export interface SupportTextProps {
  children?: ReactNode;
  className?: string;
}

/**
 * A lighter text meant to represent secondary information relative to a title / section.
 * Similar to helper text for a form input but outside the form context
 */
export default function SupportText({ children, className }: SupportTextProps) {
  return (
    <p
      className={classNames(
        className,
        'text-sm text-gray-500 max-w-md whitespace-pre-wrap'
      )}>
      {children}
    </p>
  );
}
